import React, { useRef, useState } from 'react';
import style from './Input.module.less';

const Input = ({value, setValue, placeholder, error}) => {
    const [placeStyle] = useState(value ? `${style.placeholder} ${style.placeholderEnd}` : `${style.placeholder} ${style.placeholderStart}` )

    const input = useRef(null)

    const placeholderPosition = (isFocused, input) => {
        let target = input.previousSibling
        if (target.classList.contains(style.placeholderStart) && isFocused) {
            target.classList.add(style.placeholderAnimate)
            setTimeout(() => {
                target.classList.remove(style.placeholderStart)
                target.classList.remove(style.placeholderAnimate)
                target.classList.add(style.placeholderEnd)
            }, 100);
        }
        if (target.classList.contains(style.placeholderEnd) && !isFocused && !value) {
            target.classList.add(style.placeholderAnimateReverse)
            setTimeout(() => {
                target.classList.remove(style.placeholderEnd)
                target.classList.remove(style.placeholderAnimateReverse)
                target.classList.add(style.placeholderStart)
            }, 100);
        }
    }

    const focusOnInput = () => {
        input.current.focus();
    }

    return (
        <div className={`${style.asmInputWrapper} ${error && style.asmInputError}`}
             onClick={focusOnInput}>
            <span className={placeStyle}>{placeholder}</span>
            <input className={style.asmInput}
                   value={value}
                   ref={input}
                   onBlur={(e) => placeholderPosition(false, e.target)}
                   onFocus={(e) => placeholderPosition(true, e.target)}
                   onChange={(e) => setValue(e.target.value)}
            />
        </div>
    );
};

export default Input;
