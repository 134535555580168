import React, { useEffect, useState } from 'react';
import style from './Dashboard.module.less';
import Header from '../Header';
import { useSelector } from 'react-redux';
import { questionsName } from '../../reselect/questions';
import FetchApi from '../../helpers/fetchApi';

const low = '#F44336';
const middle = '#FFA726';
const high = '#90CAF9';

const Dashboard = () => {
    const [members, setMembers] = useState([])
    const questionsNames = useSelector(questionsName)

    useEffect(() => {
        loadData()
    }, [])

    const loadData = async () => {
        const fetchApi = FetchApi('/test-result/dashboard');
        const data = await fetchApi.get();
        if (!data.isError) {
            setMembers(data);
        }
        setTimeout(() => {
            loadData()
        }, 10000)
    }

    const getLevel = (level) => {
        return level === 'NOVICE' ?
            <><span className={style.levelIcon}>🔴</span> Новичок</> :
            level === 'MIDDLE' ?
                <><span className={style.levelIcon}>🟠</span> Миддл</> :
                <><span className={style.levelIcon}>💎</span> Чемпион</>
    }

    const getScoreLine = (score) => {
        if (!Number.isInteger(score)) return;
        let barStyle = {
            width: (10 * score) + '%',
            backgroundColor: score > 6 ? high : score < 3 ? low : middle
        }
        return <>
            <div className={style.number}>{score}</div>
            <div className={style.progressBarWrapper}>
                <div className={style.progressBar}
                     style={barStyle}
                ></div>
            </div>
        </>
    }

    const getMembers = () => {
        return members.map((item, i) => <div key={i} className={style.dashboardRow}>
            <div className={style.dashboardPth}>{i+1}</div>
            <div className={style.dashboardCell}>{item.userName}</div>
            {questionsNames.map((name, j) => {
                let currentTopic = item.questionResults.filter(search => search.topic === name.text)
                return <div key={item.id + j}
                            className={style.dashboardCell}>
                    {getScoreLine(currentTopic[0]?.topicScore)}
                </div>})
            }
            <div className={style.dashboardScore}>{item.totalScore}</div>
            <div className={style.dashboardCell}>{getLevel(item.userLevel)}</div>
        </div>)
    }

    return (
        <>
            <Header dashboard={true}/>
            <div className={style.wrapper}>
                <section className={style.header}>
                    <div className={style.headerText}>
                        <span className={style.headerDescription}>Security Champion</span>
                        <span className={style.headerTitle}>Рейтинг участников</span>
                    </div>
                    <div className={style.headerIcon}>
                        <svg width="90" height="114" viewBox="0 0 90 114" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M95.92 14.4299L53.96 26.4199L12 14.4299V81.4799L53.96 93.4699L95.92 81.4799V14.4299Z" fill="#4A00EF"/>
                            <path d="M23.92 76.41L19.9399 70.72L33.05 59.71L19.9399 41.2L24.9799 36.74L42.2799 61.03L23.92 76.41Z" fill="white"/>
                            <path d="M89.5099 72.86L65.6799 79.68V72.52L89.5099 65.7V72.86Z" fill="white"/>
                        </svg>
                    </div>
                </section>
                <div>
                    <div className={style.dashboardRow}>
                        <div className={`${style.dashboardPth} ${style.tableTitle}`}>Место</div>
                        <div className={`${style.dashboardCell} ${style.tableTitle}`}>Участник</div>
                        <div className={`${style.dashboardCell} ${style.tableTitle}`}>{questionsNames[0].text}</div>
                        <div className={`${style.dashboardCell} ${style.tableTitle}`}>{questionsNames[1].text}</div>
                        <div className={`${style.dashboardCell} ${style.tableTitle}`}>{questionsNames[2].text}</div>
                        <div className={`${style.dashboardCell} ${style.tableTitle}`}>{questionsNames[3].text}</div>
                        <div className={`${style.dashboardCell} ${style.tableTitle}`}>{questionsNames[4].text}</div>
                        <div className={`${style.dashboardScoreHeader} ${style.tableTitle}`}>Итого баллов</div>
                        <div className={`${style.dashboardCell} ${style.tableTitle}`}>Уровень</div>
                    </div>
                    {getMembers()}
                </div>
            </div>
        </>
    );
};

export default Dashboard;
