import React, { useState, useEffect } from 'react';
import style from './ResultItem.module.less';

const low = '#F44336';
const middle = '#FFA726';
const high = '#90CAF9';

const ResultItem = ({title, score, topicName}) => {
    const [level, setLevel] = useState(0);

    useEffect(() => {
        let lowest = 10;
        score.forEach(item => {
            if (item < lowest) {
                lowest = item
            }
        })
        setLevel(lowest);
    }, [])

    const getLevelColor = () => {
        return level>6 ? high : level<3 ? low : middle
    }

    const getBarItem = (score) => {
        let items = [];
        let backGround = score > 6 ? high : score < 3 ? low : middle;
        for ( let i = 0; i < 10; i++) {
            items.push((
                <div className={style.topicBar} key={i}>
                    <div className={style.topicBarItem}
                         style={i<score ? {backgroundColor:backGround}: null}
                    ></div>
                </div>
            ))
        }
        return items;
    }

    const getTopic = (score, name) => {
        return <div className={style.topic} key={name.text}>
            <div className={style.topicName}>
                <span className={style.icon}>{name.icon}</span>
                <span className={style.topicText}>{name.text}</span>
                <span>{score}</span>
                <span style={{opacity: .5}}>/10</span>
            </div>
            {getBarItem(score)}
        </div>
    }
    return (
    <>
        <div className={style.title}>{title}</div>
        <div className={style.item}>
            <div className={style.itemLevel}>
                <div className={style.itemText}>{`${getLevelColor() === low ? '🔴 ' : ''}Новичок`}</div>
                <div className={style.itemBar}
                     style={{backgroundColor:getLevelColor()}}
                ></div>
            </div>
            <div className={style.itemLevel}>
                <div className={style.itemText}>{`${getLevelColor() === middle ? '🟠 ' : ''}Миддл`}</div>
                <div className={style.itemBar}
                     style={getLevelColor() === low ? null : {backgroundColor:getLevelColor()}}
                ></div>
            </div>
            <div className={style.itemLevel}>
                <div className={style.itemText}>{`${getLevelColor() === high ? '💎 ' : ''}Чемпион`}</div>
                <div className={style.itemBar}
                     style={getLevelColor() === high ? {backgroundColor:getLevelColor()} : null}
                ></div>
            </div>
        </div>
        <div>
            {topicName.map((item, i) => {
                return getTopic(score[i], item)
            })}
        </div>
    </>
    );
};

export default ResultItem;
