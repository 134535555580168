import React, { useEffect, useRef, useState } from 'react';

const XL = 1200;
const LG = 900;
const MD = 600;

const low = '#F44336';
const middle = '#FFA726';
const high = '#90CAF9';

const Canvas = ({initialCoordinate, text, wrapHeight, wrapWidth}) => {
  const [color, setColor] = useState(high);
  const [lowest] = useState(wrapHeight < wrapWidth ? wrapHeight : wrapWidth)

  useEffect(() => {
    let lowest = 10;
    initialCoordinate.forEach(item => {
      if (item < lowest) {
        lowest = item
      }
    })
    let level = lowest>6 ? high : lowest<5 ? low : middle
    setColor(level)
  }, [])

  const [coordinate] = useState(initialCoordinate.length === 5 ? [...initialCoordinate, initialCoordinate[0]]: initialCoordinate)

  const [widthCanvas, setWidth] = useState(wrapHeight);
  const [heightCanvas, setHeight] = useState(wrapWidth);
  const [corners, setCorners] = useState([]);
  const [radius, setRadius] = useState([]);
  const [ctx, setCtx] = useState(null);
  const reference = useRef(null)

  const getRadian = (corner) => {
    let pi = Math.PI
    return pi * (corner- 90)/180
  }

  const getCoordinate = (r, corner) => {
    let coordinate = [r*Math.cos(getRadian(corner)) + (widthCanvas/2), r*Math.sin(getRadian(corner)) + (heightCanvas/2)]
    return coordinate
  }

  const getText = (ctx) => {
    let textCoordinate = []
    corners.forEach((item, i) => {
      if (i >= 5) return;
      let [x, y] = getCoordinate(radius[0], item)
      switch (i) {
        case 0:
          y = y + 10;
          break;
        case 1:
          y = y + 10;
          x = x - 7;
          break;
        case 2:
          y = y + 5;
          x = x - 13;
          break;
        case 3:
          y = y + 5;
          x = x + 13;
          break;
        case 4:
          y = y + 10;
          x = x + 7;
          break;
      }

      textCoordinate.push(getCoordinate(radius[0], item))
      ctx.beginPath()
      ctx.font = "1rem serif";
      ctx.textAlign = "center";
      ctx.fillText(text[i].icon, x, y);
    })
  }

  const getCorners = () => {
    let i = 0;
    let ident = [...corners]
    while (i <= 360) {
      ident.push(i)
      i += 72
    }
    setCorners(ident);
  }

  const getRadius = (max) => {
    if (!max) return;
    let ident = [...radius]
    let j = max/11
    let i = max
    while (i >= j-1) {
      ident.push(i)
      i -= j
    }
    ident.push(0);
    setRadius(ident);
  }

  const drawStroke = ({strokeColor = color, corner, r}) => {
    ctx.beginPath()
    ctx.strokeStyle = strokeColor
    ctx.moveTo((widthCanvas/2), (heightCanvas/2))
    let coordinate = getCoordinate(r, corner)
    ctx.lineTo(coordinate[0], coordinate[1])
    ctx.stroke()
  }

  const drawSector = ([x1, y1], [x2, y2], color = 'rgba(255, 255, 255, 0.12)') => {
    ctx.beginPath()
    ctx.strokeStyle = color
    ctx.moveTo(x1, y1)
    ctx.lineTo(x2, y2)
    ctx.stroke()
  }

  const drawDemo = () => {
    if (!coordinate.length) return;
    ctx.beginPath();
    ctx.strokeStyle = color;
    ctx.fillStyle = color;
    ctx.arc(
        (widthCanvas/2),
        radius[coordinate[0]],
        4, getRadian(0),getRadian(360)
    );
    ctx.fill();
    ctx.stroke();
  }

  const draw = () => {
    if (coordinate.length) {
      ctx.beginPath()
      ctx.strokeStyle = color
      ctx.fillStyle = color+'29'
      coordinate.forEach((item, i) => {
        let coordinate = getCoordinate(radius[radius.length - 1 - item], corners[i])
        if (!i) {
          ctx.moveTo(coordinate[0], coordinate[1])
        } else if (i === 4) {
          ctx.lineTo(coordinate[0], coordinate[1])
          ctx.lineTo(getCoordinate(radius[radius.length - 1 - coordinate[0]], corners[0])[0],
              getCoordinate(radius[radius.length - 1 - coordinate[0]], corners[0])[1])
        } else {
          ctx.lineTo(coordinate[0], coordinate[1])
        }
      })
      ctx.fill()
      ctx.stroke();
    }
  }

  const getCanvas = () => {
    getCorners()
    getRadius(heightCanvas/2)
    coordinate.length > 1 ? draw() : drawDemo();
    corners.forEach((item, i, arr) => {
      drawStroke( {
        strokeColor: 'rgba(255, 255, 255, 0.12)',
        corner: item,
        r: ((lowest)/2 - lowest/22)
      })
      radius.forEach((corners, j) => {
        if (j) {
          drawSector(
              getCoordinate(corners, item),
              getCoordinate(corners, arr[i+1] || 0))
        }
      })
    })
  }

  useEffect(() => {
    const viewPortSize = window.screen.availWidth;
    let height = viewPortSize < MD ? 20 : viewPortSize < LG ? 40 : viewPortSize < XL ? 60 : 70;
    setWidth(wrapWidth);
    setHeight(lowest + height);
    let ctx = reference.current.getContext('2d')
    getCorners();
    getRadius(lowest/2);
    setCtx(ctx);
  }, [])

  useEffect(() => {
    if (ctx) {
      getText(ctx);
      getCanvas();
    }
  }, [ctx]);

  return (
      <canvas width={wrapWidth}
              height={wrapHeight < wrapWidth ? wrapHeight : wrapWidth}
              ref={reference}
      ></canvas>
  );
};

export default Canvas;
