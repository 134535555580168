import React, { useContext, useEffect, useState } from 'react';
import style from './ResultPage.module.less';
import Header from '../Header';
import Footer from '../Footer';
import Result from '../Result';
import Button from '../../UI/Button';
import CallToAction from '../CallToAction';
import { useDispatch, useSelector } from 'react-redux';
import { questionsName } from '../../reselect/questions';
import FetchApi from '../../helpers';
import { useNavigate, useParams } from 'react-router-dom';
import loaderImage from '../../images/loading-white.svg';
import successImage from '../../images/ok-white.svg';
import reloadImage from '../../images/reload-white.svg';
import {setMessage} from '../../redux/message'
import { AuthContext } from '../../context';

const LG = 1200;

const ResultPage = () => {
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const questionsNames = useSelector(questionsName);
    const [screen] = useState(window.screen.width);
    const [score, setScore] = useState([]);
    const [teamLoading, setTeamLoading] = useState(false);
    const [viewNames, setViewNames] = useState(questionsNames);
    const [questionNamesPrev, setQuestionNamesPrev] = useState([]);
    const [scoreLoading, setScoreLoading] = useState(true);
    const [linkSending, setLinkSending] = useState(false);
    const [idName, setIdName] = useState('');
    const [resultName, setResultName] = useState('');
    const [resultId, setResultId] = useState(null);
    const developer = useSelector(state => state.auth.developer);
    const teamLead = useSelector(state => state.auth.teamLead);
    const {id} = useParams();
    const isAuth = useContext(AuthContext)

    useEffect(() => {
        if (!isAuth && !id) {
            navigate('/');
            return;
        }
        const start = async() => {
            let result = null;
            let fetchApi = FetchApi(`/test-result${id ? '/search_test' : ''}`);
            if (id) {
                result = [await fetchApi.post({body: {id}})];
                console.log(result);
                setQuestionNamesPrev();
                setIdName(result[0].userName);
            } else {
                let getParams = teamLead.email || developer.email;
                result = await fetchApi.get(getParams);
                console.log(result);
            }
            setResultId(result[result.length - 1].id)
            setResultName(result[result.length - 1]?.questionResults[0]?.topic)
            let x = getResultParse(result);
            x[x.length - 1].questionResults = sortResult(x);
            let mapScore = x[x.length - 1].questionResults.map(item => item.topicScore);
            if (mapScore.length < 5) {
                sortNames(x);
            }
            setScore(mapScore);
            setScoreLoading(false);
        }
        start();
    }, [])

    const sortResult = (x) => {
        let sorted = [];
        viewNames.forEach((name, i) => {
            x[x.length - 1].questionResults.forEach((item) => {
                if (name.text === item.topic) {
                    sorted.push(item)
                }
            })
        });
        return sorted;
    }

    const sortNames = (names) => {
        let topicNames = names[0].questionResults.map(item => item.topic);
        let view = [];
        topicNames.forEach(item => {
            questionsNames.forEach(qNames => {
                if (item === qNames.text) {
                    view.push(qNames)
                }
            })
        })
        questionsNames.forEach(item => {
            if (topicNames.indexOf(item.text) === -1) {
                view.push(item);
            }
        })
        setViewNames(view);
    }

    const getResultParse = (data) => {
        if (data[data.length - 1].questionResults.length === 5) {
            return data;
        }
        let result = [];
        data.forEach(item => {
            let add = true;
            if (item.questionResults.length === 5) {
                add = false
            }
            result.forEach((returnedTopic, i) => {
                if (returnedTopic.questionResults.length === 1 && returnedTopic.questionResults[0].topic === item.questionResults[0].topic) {
                    if (new Date(returnedTopic.createdAt) < new Date(item.createdAt)) {
                        result[i] = item;
                        add = false;
                    } else {
                        add = true;
                    }
                }
            })
            if (add) {
                result.push(item);
            }
        });
        return [result.reduce((accumulator, item, i) => {
            if (!i) {
                return item;
            }
            accumulator.questionResults.push(item.questionResults[0])
            return accumulator;
        }, {})];
    }

    const replayTest = () => {
        navigate('/test')
    }

    const saveResult = async () => {
        if (id) return;
        const {location, Clipboard} = window;
        let address = location.origin + '/result/' + resultId;

        const fetchApi = FetchApi(`/result_email?resultId=${resultId}`);

        const body = {
            fullName: teamLead?.fullName || developer?.fullName,
            email: teamLead?.email || developer?.email,
            link: address,
        };
        Clipboard.copy(address);
        if (!linkSending) {
            fetchApi.post({body});
            setLinkSending(true);
        }
        dispatch(setMessage({message: 'Ссылка на результат отправлена на почту', status: 'success'}));
    }

    const teamSend = async () => {
        if (id) return;
        setTeamLoading('loading');
        const body = {
            fullName: teamLead?.fullName || developer?.fullName,
            email: teamLead?.email || developer?.email,
            teamDevs: []
        }
        const fetchApi = FetchApi('/invite_dev')
        const response = await fetchApi.post({body})
        setTeamLoading('success')
        dispatch(setMessage({message: 'Cсылка на тест отправлена на почту', status: 'success'}))
    }

    return (
        <>
            <Header />
            <div className={style.wrapper}>
                <div className={style.row}>
                    {score.length === 5 && <div className={`asm-lend-text ${style.col}`}>Поздравляем! Вы разобрали все кейсы и прошли тест «Security Champion»</div>}
                    { score.length < 5 && <div className={`asm-lend-text ${style.col}`}>Поздравляем! Вы сдали тест по навыкам безопасной разработки «{resultName}»</div>}
                    {!scoreLoading && <div className={style.col}>
                        <Result score={score}
                            title={id ? idName : teamLead?.fullName || developer?.fullName}
                            topicName={viewNames}
                            date={new Date()}/>
                    </div>}
                    <div className={style.buttonWrapper}>
                        <div className={`${style.button} ${style.col3}`}>
                            <Button text={<>
                                            <div className={style.buttonContext}>
                                                <svg style={{marginRight: '.7rem'}} width={screen < LG ? 16 : 22} height={screen < LG ? 16 : 22} viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M11.7076 17.3639L10.2933 18.7781C8.34072 20.7308 5.1749 20.7308 3.22228 18.7781C1.26966 16.8255 1.26966 13.6597 3.22228 11.7071L4.63649 10.2929M17.3644 11.7071L18.7786 10.2929C20.7312 8.34024 20.7312 5.17441 18.7786 3.22179C16.826 1.26917 13.6602 1.26917 11.7076 3.22179L10.2933 4.636M7.50045 14.4999L14.5005 7.49994" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>Сохранить результат
                                            </div>
                                        </>}
                                    btnStyle={'Opacity'}
                                    handleClick={saveResult}
                            />
                        </div>
                        <div className={`${style.button} ${style.col3}`}>
                            <Button text={<>
                                { !teamLoading ?
                                    <div className={style.buttonContext}>
                                                <svg style={{marginRight: '.7rem'}} width={screen < LG ? 16 : 22} height={screen < LG ? 16 : 22} viewBox="0 0 21 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                    <path d="M18.7914 10.6072C19.0355 10.398 19.1575 10.2933 19.2023 10.1688C19.2415 10.0596 19.2415 9.94004 19.2023 9.83077C19.1575 9.70627 19.0355 9.60164 18.7914 9.3924L10.3206 2.13178C9.9004 1.77158 9.69029 1.59148 9.5124 1.58707C9.3578 1.58323 9.21012 1.65115 9.11242 1.77103C9 1.90897 9 2.18571 9 2.73918V7.03444C6.86532 7.40789 4.91159 8.48956 3.45971 10.1137C1.87682 11.8843 1.00123 14.1757 1 16.5508V17.1628C2.04934 15.8987 3.35951 14.8763 4.84076 14.1657C6.1467 13.5392 7.55842 13.1681 9 13.0703V17.2604C9 17.8139 9 18.0906 9.11242 18.2286C9.21012 18.3485 9.3578 18.4164 9.5124 18.4125C9.69029 18.4081 9.9004 18.228 10.3206 17.8678L18.7914 10.6072Z" stroke="white" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"/>
                                                </svg>Отправить тест коллеге
                                    </div> : teamLoading === 'loading' ?
                                        (<div className={style.iconWrapper}><img src={loaderImage} className={style.icon}/></div>) : teamLoading === 'success' ?
                                            (<div className={style.iconWrapper}><img src={successImage} className={style.icon}/></div>) :
                                            (<div className={style.iconWrapper}><img src={reloadImage} className={style.icon}/></div>)
                                }
                            </>}
                                    btnStyle={'Opacity'}
                                    handleClick={teamSend}
                            />
                        </div>
                        <div className={style.button}>
                            <Button text={<div className={style.buttonContext}>Пересдать</div>}
                                    btnStyle={'Main'}
                                    handleClick={replayTest}
                            />
                        </div>
                    </div>

                    <div><CallToAction /></div>
                </div>
            </div>
            <Footer />
        </>
)};

export default ResultPage;
