import React, {useState} from 'react';
import style from './OneAuth.module.less';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import Header from '../Header';
import Footer from '../Footer';
import Link from '../../UI/Link';
import { useSelector, useDispatch } from 'react-redux';
import { validRules } from '../../helpers';
import { signupTeamLead } from '../../redux/auth';
import { useNavigate } from 'react-router-dom';


const READY = 'ready';
const LOADING = 'loading';
const SUCCESS = 'success';
const RELOAD = 'reload';

const OneAuth = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [authError,setAuthError] = useState(false);
    const [errorForm, setErrorForm] = useState(false);
    const [status, setStatus] = useState()
    const dispatch = useDispatch();
    let navigate = useNavigate();
    const rule = {
        email: {
            required: true,
            email: true
        },
        name: {
            required: true
        }
    }
    const topic = useSelector(state => state.topic.name);

    const send = () => {
        setAuthError(false);
        let validate = validRules(rule, [{name: 'name', value: name}, {name: 'email', value: email}]);
        if (validate) {
            setErrorForm(validate);
            setStatus(READY);
            return;
        }
        setErrorForm(validate);
        setStatus(LOADING);
        const body = {
            fullName: name,
            email,
            teamDevs: [],
            topicName: topic
        }
        dispatch(signupTeamLead(body))
        navigate('/test');
        setStatus(SUCCESS);
        setTimeout(() => {
            setStatus(RELOAD);
        }, 3000);
    }

    return (
        <>
            <Header/>
            <div className={style.wrapper}>
                <div className={style.container}>
                    <div className={`asm-lend-text ${style.col}`}>Тест по навыку безопасной разработки</div>
                    <div className={`asm-main-title ${style.col}`}>{topic}</div>
                    <div className={`asm-lend-text ${style.col}`}>Посмотрите 4 реальных кейса, когда известные приложения были взломаны, и узнайте, смогли бы вы написать код лучше.</div>
                    <div className={style.form}>
                        <div className={style.formInput}>
                            <Input value={name}
                                   setValue={setName}
                                   placeholder={'Имя и фамилия'}
                            />
                        </div>
                        <div className={style.formInput}>
                            <Input value={email}
                                   setValue={setEmail}
                                   placeholder={'Рабочая почта'}
                            />
                        </div>
                        <div className={style.formButton}>
                            <Button text={'Погнали!'}
                                    handleClick={send}
                                    btnStyle={'Main'}
                            />
                        </div>
                        {(status === SUCCESS || status === RELOAD) && <div className={style.formText}>По адресу {email} отправлена ссылка на персональный тест.</div>}
                    </div>
                    <div className={style.col}>
                        <span className={style.text}>Нажимая «Погнали!» я соглашаюсь</span>
                        <Link linkClass={'Default'}
                              type={'_blank'}
                              address={'https://docs.google.com/document/d/12ToToSnonyS5nAACBzDavQz7vvBEx8lgi9qxKnX9R5M'}
                        >с политикой конфиденциальности и условиями обработки персональных данных</Link>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
};

export default OneAuth;
