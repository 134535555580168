import React, { useState } from 'react';
import style from './DeveloperAuth.module.less';
import PrivacyPolicy from '../PrivacyPolicy';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import { signupDev, signupTeamLead } from '../../redux/auth';
import { useDispatch, useSelector } from 'react-redux';
import { validRules } from '../../helpers';
import { useNavigate } from 'react-router-dom';

const DeveloperAuth = () => {
    const dispatch = useDispatch();
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const TL_data = useSelector(state => state.auth.teamLead);
    let navigate = useNavigate();
    const rule = {
        email: {required: true, email: true},
        name: {required: true}
    }

    const send = async () => {
        setError(false);
        let validate = validRules(rule, [{name: 'name', value: name}, {name: 'email', value: email}]);
        if (validate) {
            setError(true);
            return;
        }
        let body = {
            teamLeadId: TL_data.teamLeadId,
            email: email,
            fullName:name
        }

        TL_data.teamLeadId ? dispatch(signupDev(body)) : dispatch(signupTeamLead(body))
        navigate('/test');
    }

    return (
        <div className={style.wrapper}>
          {/*<div className={style.hello}>{TL_data.fullName} считает, что вы –</div>*/}
          <div className={style.title}>Security Champion</div>
          <div className={`${style.text} ${style.textWrap}`}>{`Похоже, вы уже занимаетесь разработкой и решаете важные задачи, которые требуют:\r\n` +
              `— предотвращения уязвимости переноса строки (CRLF),\r\n` +
              `— работы с базами данных (SQL),\r\n` +
              `— выполнения кода (RCE),\r\n` +
              `— вывода информации в браузер (XSS),\r\n` +
              `— знания DevSecOps.`}</div>
          <div className={style.text}>Security Champion – это не просто хороший разработчик, а тот, чьи сайты и приложения хакеры не могут взломать.</div>
          <div className={style.text}>Посмотрите 20 реальных кейсов, когда известные приложения были взломаны, и узнайте, смогли бы вы написать код лучше.</div>
          <div className={style.form}>
              <div className={style.formInput}>
                  <Input value={name}
                         error={error}
                         setValue={setName}
                         placeholder={'Имя и фамилия'}
                  />
              </div>
              <div className={style.formInput}>
                  <Input value={email}
                         error={error}
                         setValue={setEmail}
                         placeholder={'Рабочая почта'}
                  />
              </div>
              <div className={style.formButton}>
                  <Button text={'Погнали!'}
                          handleClick={send}
                          btnStyle={'Main'}
                  />
              </div>
          </div>
          <div className={style.policy}>
              <PrivacyPolicy type={'Main'}/>
          </div>
        </div>
    );
};

export default DeveloperAuth;
