import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	result: [],
};

const questionResultSlice = createSlice({
	name: 'message',
	initialState,
	reducers: {
		setResult (state, {payload}) {
			state.result = payload;
		},
		setResultDefault (state) {
			state.result = initialState.result;
		}
	},
});
const {setResultDefault, setResult} = questionResultSlice.actions;
export {setResultDefault, setResult};
export default questionResultSlice.reducer;
