import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	status: 'none',
	message: '',
};

const activeQuestionSlice = createSlice({
	name: 'message',
	initialState,
	reducers: {
		setMessage (state, {payload}) {
			state.message = payload.message;
			state.status = payload.status;
		},
		setDefault (state) {
			state.message = initialState.message;
			state.status = initialState.status;
		}
	},
});
const {setMessage, setDefault} = activeQuestionSlice.actions;
export {setMessage, setDefault};
export default activeQuestionSlice.reducer;
