import React, { useEffect, useState } from 'react';
import * as ReactDOM from 'react-dom';

const AsmModal = ({children}) => {
    const [modalRoot] = useState(document.querySelector('#asm-modal'))
    const [el] = useState(document.createElement('div'))
    useEffect(() => {
        el.classList.add('popup')
        modalRoot.appendChild(el);
        return () => {
            modalRoot.removeChild(el);
        }
    }, [])
    return ReactDOM.createPortal(
        children,
        el
    );
};

export default AsmModal;
