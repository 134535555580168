import { createSlice } from '@reduxjs/toolkit';

const initialState = {
	name: ''
};

const activeTopicSlice = createSlice({
	name: 'activeTopic',
	initialState,
	reducers: {
		setTopic (state, {payload}) {
			state.name = payload
		},
		setDefaultTopic (state) {
			state.name = initialState.topic;
		}
	},
});

export const {setTopic, setDefaultTopic} = activeTopicSlice.actions;
export default activeTopicSlice.reducer;
