import React from 'react';
import style from './DemoBlock.module.less';
import { useDispatch } from 'react-redux';
import { setTopic } from '../../redux/activeTopick';
import { useNavigate } from 'react-router-dom';

const DemoBlock = ({topic, isAuth, goTo, setAuthError}) => {
	const dispatch = useDispatch();
	let navigate = useNavigate();

	const handleClick = (item) => {
		const {localStorage} = window;
		localStorage.removeItem('result');
		localStorage.removeItem('questions');
		if (isAuth) {
			dispatch(setTopic(item));
			navigate('/test')
		} else {
			dispatch(setTopic(item));
			navigate('/test_one')
		}
	}

	return (
		<div className={style.wrapper}>
			{topic.map(item => <div key={item.text}
			                        className={style.itemWrapper}
			                        onClick={() => handleClick(item.text)}
			>
				<div>{item.icon}</div>
				<div className={style.text}>{item.text}</div>
				<div className={style.button}>
					<svg className={style.arrow}
					     width="26"
					     height="20"
					     viewBox="0 0 26 20"
					     fill="none"
					     xmlns="http://www.w3.org/2000/svg">
						<path d="M2.33331 10H23.6666M23.6666 10L15.6666 2M23.6666 10L15.6666 18"
						      strokeWidth="3"
						      strokeLinecap="round"
						      strokeLinejoin="round"/>
					</svg>
				</div>
			</div>)}
		</div>
	);
};

export default DemoBlock;
