import React, { useEffect, useRef, useState } from 'react';
import style from './Result.module.less';
import Canvas from '../Canvas';
import ResultItem from './ResultItem';
import Link from '../../UI/Link';

const Result = ({title, score, topicName, date, isDemo = false}) => {
	const canvasWrapper = useRef(null);
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);
	const [render, setRender] = useState(false)

	useEffect(() => {
		if (canvasWrapper.current) {
			setWidth(canvasWrapper.current.offsetWidth);
			let height = canvasWrapper.current.parentNode.offsetHeight - canvasWrapper.current.parentNode.children[2].offsetHeight
			setHeight(height);
			setRender(true)
		}
	}, [canvasWrapper])

	return (
		<div className={style.wrapper}>
			<div className={style.canvasWrapper} ref={canvasWrapper}>
				{render && <Canvas initialCoordinate={score}
				        text={topicName}
				        wrapWidth={width}
				        wrapHeight={height}
				/>}
			</div>
			<div className={style.data} >
				<ResultItem title={title}
				            score={score}
				            topicName={topicName}
				/>
			</div>
			<div className={style.footer}>
				<div className={style.footerText}>
					Результат на {new Date(date).toLocaleDateString()}
				</div>
				<div>
					{/* ссылка на дашборд */}
					{/*<Link text={`Рейтинг участников`}*/}
					{/*      linkClass={'Main'}*/}
					{/*      address={isDemo ? '':'/dashboard'}*/}
					{/*/>*/}
				</div>
			</div>
		</div>
	);
};

export default Result;
