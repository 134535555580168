import React, { useContext, useEffect, useRef, useState } from 'react';
import style from './LandingWrapper.module.less';
import Header from '../Header';
import AuthForm from '../AuthForm';
import DemoBlock from '../DemoBlock';
import Result from '../Result';
import PrivacyPolicy from '../PrivacyPolicy';
import { useDispatch, useSelector } from 'react-redux';
import { questionsName } from '../../reselect/questions';
import { signupTeamLead } from '../../redux/auth';
import FetchApi from '../../helpers';
import Footer from '../Footer';
import useClientHeight from '../../hook/useClientHeight';
import Button from '../../UI/Button';
import { AuthContext } from '../../context';
import { useNavigate } from 'react-router-dom';

const MAIN = 'Main';
const DEMO_SCORE = [2,8,7,5,10];

const LandingWrapper = () => {
    const isAuth = useContext(AuthContext)
    const dispatch = useDispatch();
    const clientHeight = useClientHeight();
    const [showButton, setShowButton] = useState(0);
    const [authError, setAuthError] = useState(false);
    const questionsNames = useSelector(questionsName);
    const sectionAuth = useRef(null);
    const loadQuestions = useSelector(state => state.questions.status === 'fulfilled')
    const navigate = useNavigate();

    const getAuthText = (type) => {
        return <>
            <PrivacyPolicy type={type} text={'Нажимая «Отправить команде» или «Пройти самостоятельно»'}/>
        </>
    }

    useEffect(() => {
        let {y, height} = sectionAuth.current.getBoundingClientRect();
        setShowButton(height + y)
    }, [clientHeight])

    const fetchSelfAuth = (name, email) => {
        const body = {
            fullName: name,
            email,
            teamDevs: []
        }
        dispatch(signupTeamLead(body))
        navigate('/test')
    }

    const scrollPageTo = () => {
        window.scrollTo(0, 0);
    }

    const fetchTeamInvite = async (name, email) => {
        const body = {
            fullName: name,
            email,
            teamDevs: []
        }
        const fetchApi = FetchApi('/invite_dev')
        const response = await fetchApi.post({body})
        if (response.isError) {
            console.log('alert');
        }
    }

    return (<>
        <Header />
        <div className={style.wrapper}>
            <section className={'asm-header'} ref={sectionAuth}>
                <div className={`asm-header_wrapper ${style.col} ${style.mainTitle}`}>
                    <div className={'asm-lend-text'}>Тест для продуктовых команд</div>
                    <div className={'asm-main-title'} style={{color: '#fff'}}>Security Champion</div>
                    <div className={`asm-lend-text`}>Узнайте, кто из ваших коллег — настоящий Security Champion. Оцените навыки безопасной разработки и получите карту компетенций для каждого члена команды.</div>
                </div>
                <AuthForm rule={{email: {required: true, email: true},name: {required: true}}}
                          handleSendTeam={fetchTeamInvite}
                          handleSendSelf={fetchSelfAuth}
                          setAuthError={setAuthError}
                          title={'Самому'}
                          type={MAIN}
                          error={authError}
                          Style={{backgroundColor: 'rgba(253, 216, 53, 0.08)'}}
                >
                    {getAuthText(MAIN)}
                </AuthForm>
            </section>
            <div className={style.sectionHeader}>
                <div className={style.sectionHeaderTitle}>
                    Какие навыки проверяем
                </div>
                <div className={style.sectionHeaderText}>Тест составлен из 20 реальных кейсов, когда были взломаны известные приложения. Кейсы отличаются по сложности и проверяют пять важных навыков безопасной разработки.</div>
            </div>
            <div className={style.demoWrapper}>
                <DemoBlock topic={questionsNames}
                           isAuth={isAuth}
                           goTo={scrollPageTo}
                           setAuthError={setAuthError}
                />
            </div>
            <div className={style.sectionHeader}>
                <div className={style.sectionHeaderTitle}>
                    Как выглядит результат
                </div>
                <div className={style.sectionHeaderText}>После прохождения составляем карту компетенций, на которой показываем уровень навыков разработчика по каждой теме</div>
            </div>
            <div className={style.sectionResult}>
                {loadQuestions && <Result title={'Алексей Рыбаков'}
                        score={DEMO_SCORE}
                        topicName={questionsNames}
                        date={new Date()}
                        isDemo={true}
                />}
            </div>
            {showButton < 0 && <div className={style.scrollButton}>
                <Button text={'Пройти тест'}
                        handleClick={scrollPageTo}
                        btnStyle={'Main'}
                />
            </div>}
        </div>
            <Footer/>
        </>
    );
};

export default LandingWrapper;
