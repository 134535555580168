import React, { useEffect, useState } from 'react';
import style from './AuthForm.module.less';
import Input from '../../UI/Input';
import Button from '../../UI/Button';
import {validRules} from '../../helpers';
import loaderImage from '../../images/loading.svg';
import reloadImage from '../../images/reload.svg';
import successImage from '../../images/ok.svg';

const READY = 'ready';
const LOADING = 'loading';
const SUCCESS = 'success';
const RELOAD = 'reload';

const AuthForm = ({rule, handleSendSelf, handleSendTeam, children, error, setAuthError}) => {
    const [name, setName] = useState('')
    const [email, setEmail] = useState('')
    const [selfStatus, setSelfStatus] = useState(READY)
    const [teamStatus, setTeamStatus] = useState(READY)
    const [errorForm, setErrorForm] = useState(false);

    useEffect(() => {
        setAuthError(false);
    }, [name, email])

    const sendSelf = () => {
        setAuthError(false);
        let validate = validRules(rule, [{name: 'name', value: name}, {name: 'email', value: email}]);
        if (validate) {
            setErrorForm(validate);
            setSelfStatus(READY);
            return;
        }
        setErrorForm(validate);
        setSelfStatus(LOADING);
        handleSendSelf(name, email);
        setSelfStatus(SUCCESS);
        setTimeout(() => {
            setSelfStatus(RELOAD);
        }, 3000);
    }

    const sendTeam = () => {
        setAuthError(false);
        let validate = validRules(rule, [{name: 'name', value: name}, {name: 'email', value: email}]);
        if (validate) {
            setErrorForm(validate);
            setTeamStatus(READY);
            return;
        }
        setErrorForm(validate);
        setTeamStatus(LOADING);
        handleSendTeam(name, email);
        setTeamStatus(SUCCESS);
        setTimeout(() => {
            setTeamStatus(RELOAD);
        }, 3000);
    }

    return (
        <div className={`${style.wrapper} ${style.wrapperPrime} ${error && style.wrapperError}`}>
            <div className={style.inputWrapper}>
                <Input value={name}
                       setValue={setName}
                       error={errorForm?.name}
                       placeholder={'Имя и фамилия'}
                />
            </div>
            <div className={style.inputWrapper}>
                <Input value={email}
                       setValue={setEmail}
                       error={errorForm?.email}
                       placeholder={'Рабочая почта'}
                />
            </div>
            <div className={style.buttonWrapper}>
                <Button text={teamStatus === LOADING ?
                    (<div className={style.iconWrapper}><img src={loaderImage} className={style.icon}/></div>) :
                    teamStatus === SUCCESS ?
                        (<div className={style.iconWrapper}><img src={successImage} className={style.icon}/></div>) :
                        teamStatus === RELOAD ?
                            (<div className={style.iconWrapper}><img src={reloadImage} className={style.icon}/></div>) : 'Отправить команде'}
                        handleClick={sendTeam}
                        btnStyle={'Main'}
                />
            </div>
            {(teamStatus === SUCCESS ||  teamStatus === RELOAD) &&
            <div className={style.text}>По адресу {email} отправлена ссылка на тест для вашей команды.</div>}
            <div className={style.buttonWrapper}>
                <Button text={selfStatus === LOADING ?
                    (<div className={style.iconWrapper}><img src={loaderImage} className={style.icon}/></div>) :
                    selfStatus === SUCCESS ?
                        (<div className={style.iconWrapper}><img src={successImage} className={style.icon}/></div>) :
                        selfStatus === RELOAD ?
                            (<div className={style.iconWrapper}><img src={reloadImage} className={style.icon}/></div>) : 'Пройти самостоятельно'}
                        handleClick={sendSelf}
                        btnStyle={'Opacity'}
                />
            </div>
            {(selfStatus === SUCCESS || selfStatus === RELOAD) &&
            <div className={style.text}>По адресу {email} отправлена ссылка на персональный тест.</div>}
            <div className={style.text}>
                {children}
            </div>
        </div>
    );
};

export default AuthForm;
