import { RouterProvider } from 'react-router-dom';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { fetchQuestions } from './redux/questions';
import { AuthContext } from './context.js';
import AsmModal from './UI/AsmModal';
import IcoError from './images/notificationError.svg';
import IcoSuccess from './images/notificationSuccess.svg';
import { setDefault } from './redux/message';
import { fetchTlAuth, fetchDvAuth, fetchDEVAuth, setAuth } from './redux/auth';
import {router} from './rout';
import { ApiLocalStorage } from './helpers/SetLocalStorage';
import { setTopic } from './redux/activeTopick';

function App() {
    const status = useSelector(state => state.questions.status);
    const message = useSelector(state => state.message);
    const auth = useSelector(state => state.auth.status);
    const {location} = window;
    const dispatch = useDispatch();

    useEffect( () => {
        let authData = ApiLocalStorage('authData')
        let locationSearch = location.search.replace('?', '').split('&');
        locationSearch.forEach(item => {
            if (item.indexOf('topic=') !== -1) {
                let topicName = decodeURI(item.replace('topic=', ''));
                if (topicName) {
                    dispatch(setTopic(topicName));
                }
            }
        });
        if (authData?.email) {
            dispatch(setAuth(authData));
        } else if (~location.pathname.indexOf('confirm_email/dev') && ~location.search.indexOf('?email')) {
            dispatch(fetchDEVAuth());
        } else if (location.pathname.indexOf('confirm_email/dev') !== -1) {
            dispatch(fetchDvAuth());
        } else if (location.pathname.indexOf('confirm_email') !== -1) {
            dispatch(fetchTlAuth());
        }
        dispatch(fetchQuestions());
    }, [])

    useEffect(() => {
        if (message.status !== 'none') {
            setTimeout(() => {
                dispatch(setDefault())
            } , 8000)
        }
    }, [message])

    return (
        <AuthContext.Provider value={auth}>
            <div className="App">
                {status === 'fulfilled' && <RouterProvider router={router}/>}
            </div>
            {message.message && <AsmModal>
                <div className={'popup-back'}>
	                <div className={'popup-wrapper'}>{message.status === 'error' ?
                        <img className={'popup-icon'} src={IcoError} alt={'error'}/> :
                        <img className={'popup-icon'} src={IcoSuccess} alt={'success'}/>}{message.message}</div>
                </div>
            </AsmModal>}
        </AuthContext.Provider>
    )
}

export default App;
