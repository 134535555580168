import React from 'react';
import Link from '../../UI/Link';

const PrivacyPolicy = ({type, text= 'Нажимая «Погнали!»'}) => {
  return (
    <>
      <span>{text} я соглашаюсь </span>
      <Link address={'https://docs.google.com/document/d/12ToToSnonyS5nAACBzDavQz7vvBEx8lgi9qxKnX9R5M'}
            type={'_blank'}
            linkClass={type}
      >с политикой конфиденциальности и условиями обработки персональных данных</Link>
      <span>.</span>
    </>
  );
};

export default PrivacyPolicy;
