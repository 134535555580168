import React, { useEffect, useState } from 'react';
import style from './Question.module.less';

const Question = ({question, handleAnswers, active = null}) => {
    const setActive = (i) => {

        handleAnswers({
            answerId: i,
            questionId: question.id,
            topic: question.topic
        });
    }

    const getQuestionBody = () => {
        return question.questionBody.map(item => {
            if (item.type === 'text') {
                return <div key={item.value} className={`${style.questionText}`}>{item.value}</div>
            }
            return <iframe 
            sandbox="allow-same-origin allow-scripts"
            key={item.value} className={`${style.frame}`} src={item.value} />
        })
    }

    const getAnswers = () => {
        return question.answers.map((item) => (
            <div key={item.id}
                 className={style.answerWrapper}
                 onClick={() => {setActive(item.id)}}>
                <div className={`${style.checkbox} ${active === item.id && style.checkboxActive}`}></div>
                <div className={style.answerText}>{item.label}</div>
            </div>
        ));
    }

    return (
        <div className={style.wrapper}>
            <div className={style.rowFlex}>
                <div className={style.question}>
                    <div className={style.questionBody}>
                        {getQuestionBody()}
                    </div>
                    <div className={style.answer}>
                        {getAnswers()}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Question;
