import React from 'react';
import style from './CallToAction.module.less';
import image from '../../images/Page.png';
import Link from '../../UI/Link';

const CallToAction = () => {
    return (
        <div className={style.asmCall}>
            <div className={style.asmCallTitle}>Попробуйте интерактивный модуль обучения для продуктовых команд Start EDU</div>
            <div className={style.asmCallLink}>Для проведения демонстрации напишите нам на:</div>
            <div className={style.asmCallLink}>
                <Link text={''}
                      address={'devsecops@startx.team'}
                      linkClass={'Main'}
                      type={'_blank'}
                >devsecops@startx.team</Link>
            </div>
            <img className={style.asmCallPage} src={image} alt="картинка"/>
        </div>
    );
};

export default CallToAction;
