import React, { useEffect, useState } from 'react';

const useClientHeight = () => {
    const [clientHeight, setClientHeight] = useState(0)

    const setScroll = (e) => {
        setClientHeight(e)
    }

    useEffect(() => {
        document.addEventListener('scroll', () => setScroll(window.pageYOffset));
        return document.removeEventListener('scroll', setScroll);
    }, [])
    return clientHeight;
};

export default useClientHeight;
