import React from 'react';
import style from './Header.module.less';
import Link from '../../UI/Link';
import logo from '../../images/logo.svg';

const Header = ({dashboard}) => {

  return (
    <header className={style.headerWrapper}>
        <div className={dashboard? style.bigWrapper : style.container}>
            <div className={style.row}>
                <span className={style.logo}>
                    <Link address={'https://antiphish.ru/products/start-edu'}
                          linkClass={'DefaultNoLink'}
                          type={'_blank'}
                    ><img className={style.logoImg} src={logo}/></Link>
                </span>
                <div className={style.link}>
                    <Link address={'mailto:devsecops@startx.team'}
                          linkClass={'Default'}
                          type={'_blank'}
                    >devsecops@startx.team</Link>
                </div>
            </div>
        </div>
    </header>
  );
};

export default Header;
