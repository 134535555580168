import React from 'react';
import style from './Footer.module.less';
import Link from '../../UI/Link';

const Footer = () => {
  return (
    <div className={style.wrapper}>
        <div className={style.container}>
            <div className={style.row}>
                <div className={style.description}>© Start X 2016–2024</div>
                <Link address={'mailto:info@startx.team'}
                      linkClass={'Main'}
                      type={'_blank'}
                >info@startx.team</Link>
            </div>
        </div>
    </div>
  );
};

export default Footer;
