import { configureStore } from '@reduxjs/toolkit'
import questions from './questions';
import message from './message';
import auth from './auth';
import topic from './activeTopick';
import questionsResult from './questionsResult';

export const store = configureStore({
    reducer: {
        questions,
        message,
        auth,
        questionsResult,
        topic,
    },
})
