import React from 'react';
import style from './Link.module.less';

const Link = ({address, children, type, linkClass = 'Prime'}) => {
  return (
    <a className={`${style.link} ${style['link'+linkClass]}`}
       href={address}
       target={type}
    >
      {children}
    </a>
  )
}

export default Link;
